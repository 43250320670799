import React, { FunctionComponent, useContext, useEffect } from 'react';
import Element from './formularElement';
import LazyComponentLoader from '../componentLoading/lazyComponentLoader';
import ElementParser from './elementParser';
import {
  ThirdPartySystemsConfigFb,
  ThirdPartySystemsServiceInstance,
} from '../services/thirdPartySystemsService';
import { TSetAdditionalErrorMessage } from './configContext';
import saveAndRetrieveContext, {
  SafeAndRetrieveContext,
} from '../contexte/saveAndRetrieveContext';
import { PageStepperContext } from './pageStepperContext';

interface ComponentBuilderProps {
  element: Element;
  keys?: Array<number>;
  formThirdPartySystemsConfig?: ThirdPartySystemsConfigFb;
  // setAdditionalErrorMessage: TSetAdditionalErrorMessage;
}

const ComponentBuilder: FunctionComponent<ComponentBuilderProps> = ({
  element: {
    refComponent,
    thirdPartySystemsConfig,
    elements,
    ...additionalProps
  },
  keys = [],
  formThirdPartySystemsConfig,
  //setAdditionalErrorMessage,
}) => {
  useEffect(() => {
    console.log('systems: ', formThirdPartySystemsConfig);
  }, [thirdPartySystemsConfig]);

  return (
    <LazyComponentLoader
      refComponent={refComponent}
      /*  onChange={getOnChange(
        thirdPartySystemsConfig,
        () => null,
       // pageStepperCtxt.setAdditionalErrorMessage,
        formThirdPartySystemsConfig
      )}*/
      validateFn={ThirdPartySystemsServiceInstance.getValidateFn(
        thirdPartySystemsConfig,
        formThirdPartySystemsConfig
      )}
      {...additionalProps}
      elements={elements}
    >
      {elements && elements.length > 0 && (
        <ElementParser
          keys={keys}
          elements={elements}
          formThirdPartySystemsConfig={formThirdPartySystemsConfig}
          // setAdditionalErrorMessage={sr?.setAdditionalErrorMessage}
        />
      )}
    </LazyComponentLoader>
  );
};

export default React.memo(ComponentBuilder);
