import FormularElement from '../formBuilder/formularElement';
import { FormApi } from 'final-form';
import { FieldMetaState } from 'react-final-form';

export interface ThirdPartySystemsConfigFb {
  errorMessages?: ThirdPartySystemsErrorMessage[];
  systems?: ThirdPartySystemFb[];
}

interface ThirdPartySystemsErrorMessage {
  identifier?: string;
  message?: string;
}

export interface ThirdPartySystemFb {
  name?: string;
  type?: string;
  backendUrl?: string;
  id: string;
}

export interface IOnChange {
  newVal: unknown;
  mutators: FormApi['mutators'];
  setErrorMessage: (newErrorMessage: string | undefined) => void;
  fieldName: string;
  meta: FieldMetaState<any>;
}

const getTypeA = (newVal: unknown, system: ThirdPartySystemFb) => {
  if (!system.backendUrl) {
    console.error('Missing backend url for third party system ', system);
    return;
  }
  return fetch(system.backendUrl?.concat(encodeURIComponent(String(newVal))), {
    method: 'GET',
    headers: { accept: 'application/json' },
  });
};

/*export const getOnChange = (
  thirdPartySystemsConfig: FormularElement['thirdPartySystemsConfig'],
  setErrorMessage: (fieldName: string, message: string | null) => void,
  formThirdPartySystemsConfig?: ThirdPartySystemsConfigFb
): ((props: IOnChange) => void) => {
  if (
    thirdPartySystemsConfig &&
    thirdPartySystemsConfig?.length >= 1 &&
    thirdPartySystemsConfig[0].isChecked
  ) {
    const matchingSystem: ThirdPartySystemFb | undefined =
      formThirdPartySystemsConfig?.systems?.find(
        (item: ThirdPartySystemFb) =>
          item.id === thirdPartySystemsConfig[0].systemId
      );
    if (matchingSystem?.type === 'a') {
      return (props: IOnChange) => {
        if (props.newVal === undefined) return;
        const response = getTypeA(props.newVal, matchingSystem);
        response
          ?.then((resp: Response) => resp.json())
          .then((resp: { data?: Record<string, unknown>; status?: string }) => {
            if (resp.status?.toUpperCase() === 'OK' || !resp.status) {
              resp?.data &&
                Object.keys(resp.data)?.forEach(
                  (field: string) =>
                    resp.data &&
                    props.mutators.setValue(field, resp.data[field])
                );
              props.setErrorMessage(undefined);
              // setErrorMessage("firstname", null)
           //   SchemaBuilder.setAdditionalErrorMessage('firstname', null);
            } else {
                   props.setErrorMessage(
                     formThirdPartySystemsConfig?.errorMessages?.find(
                       (message: ThirdPartySystemsErrorMessage) =>
                         message.identifier === resp.status
                     )?.message
                   );
              // TODO use error state
              //   setErrorMessage("firstname", "Das gibts net")
              //props.mutators.setError(['firstname', 'df']);
              SchemaBuilder.setAdditionalErrorMessage(
                'firstname',
                'Das gibts net'
              );
            }
          });
      };
    }
  }
  return (props: IOnChange) => {};
};*/

class ThirdPartySystemsService {
  oldValues: Record<string, unknown> = {};
  oldErrorMessages: Record<string, string> = {};
  getValidateFn = (
    thirdPartySystemsConfig: FormularElement['thirdPartySystemsConfig'],
    formThirdPartySystemsConfig?: ThirdPartySystemsConfigFb
  ) => {
    if (
      thirdPartySystemsConfig &&
      thirdPartySystemsConfig?.length >= 1 &&
      thirdPartySystemsConfig[0].isChecked
    ) {
      const matchingSystem: ThirdPartySystemFb | undefined =
        formThirdPartySystemsConfig?.systems?.find(
          (item: ThirdPartySystemFb) =>
            item.id === thirdPartySystemsConfig[0].systemId
        );
      if (matchingSystem?.type === 'a') {
        return async (props: IOnChange): Promise<string | null> => {
          if (props.newVal === undefined) return null;
          if (this.oldValues[props.fieldName] === props.newVal) {
            return this.oldErrorMessages[props.fieldName];
          }
          this.oldValues[props.fieldName] = props.newVal;
          const response = getTypeA(props.newVal, matchingSystem);
          let result = null;
          await response
            ?.then((resp: Response) => resp.json())
            .then(
              (resp: { data?: Record<string, unknown>; status?: string }) => {
                if (resp.status?.toUpperCase() === 'OK' || !resp.status) {
                  resp?.data &&
                    Object.keys(resp.data)?.forEach(
                      (field: string) =>
                        resp.data &&
                        props.mutators.setValue(field, resp.data[field])
                    );
                  props.setErrorMessage(undefined);
                  result = null;
                  delete this.oldErrorMessages[props.fieldName];
                } else {
                  result =
                    formThirdPartySystemsConfig?.errorMessages?.find(
                      (message: ThirdPartySystemsErrorMessage) =>
                        message.identifier === resp.status
                    )?.message ?? resp.status;
                  this.oldErrorMessages[props.fieldName] = result;
                }
              }
            );
          return result;
        };
      }
    }
    return (props: IOnChange) => {};
  };
}

export const ThirdPartySystemsServiceInstance = Object.freeze(
  new ThirdPartySystemsService()
);
