import { Grid, GridSize, GridSpacing, Skeleton, useTheme } from '@mui/material';
import React, { FunctionComponent, Suspense } from 'react';
import ConditionalField from '../../components/@material/ConditionalField';
import Element from '../formBuilder/formularElement';
import { magicImport } from './magicImport';

interface LazyComponentLoaderProps {
  refComponent: string;
  componentLoadingFnct?: Function;
  elements?: Element[];
  sm?: GridSize;
  xs?: GridSize;
  container?: boolean;
  fieldName?: string;
  rulesConfig?: any;
  excludeGrid?: boolean;
  onChange?: any;
  validateFn?: any;
}

const customComponentLoadingFnct = (refComponent: string) =>
  React.lazy(magicImport(refComponent));

const LazyComponentLoader: FunctionComponent<LazyComponentLoaderProps> = ({
  refComponent,
  componentLoadingFnct = customComponentLoadingFnct,
  children,
  sm,
  xs,
  container,
  excludeGrid,
  fieldName,
  rulesConfig,
  ...props
}) => {
  const CustomComponent: React.LazyExoticComponent<React.ComponentType<any>> =
    componentLoadingFnct(refComponent);

  let defaults = getDefaultLayoutValues(refComponent);
  xs = xs ?? (defaults.xs as GridSize);
  sm = sm ?? (defaults.sm as GridSize);
  container = container ?? defaults.container;

  return (
    <>
      {excludeGrid ? (
        <Suspense fallback={<Skeleton width={'100%'} height={'50px'} />}>
          <CustomComponent {...props}>{children}</CustomComponent>
        </Suspense>
      ) : container ? (
        <Grid justifyContent="center" container spacing={0}>
          <Suspense fallback={<Skeleton width={'100%'} height={'50px'} />}>
            <CustomComponent {...props}>{children}</CustomComponent>
          </Suspense>
        </Grid>
      ) : fieldName && rulesConfig?.length > 0 ? (
        <Grid item xs={xs} sm={sm}>
          <ConditionalField
            fieldName={fieldName}
            rulesConfig={rulesConfig}
            elements={props?.elements}
          >
            <Suspense fallback={<Skeleton width={'100%'} height={'50px'} />}>
              <CustomComponent {...props} fieldName={fieldName}>
                {children}
              </CustomComponent>
            </Suspense>
          </ConditionalField>
        </Grid>
      ) : (
        <Grid item xs={xs} sm={sm}>
          <Suspense fallback={<Skeleton width={'100%'} height={'50px'} />}>
            <CustomComponent {...props} fieldName={fieldName}>
              {children}
            </CustomComponent>
          </Suspense>
        </Grid>
      )}
    </>
  );
};

//TODO: sollte später in globale metainformationen verschoben werden
function getDefaultLayoutValues(refComponent: string) {
  let xs, sm, container;
  switch (refComponent) {
    case 'components/@material/informationComponent':
      xs = 'auto';
      sm = 'auto';
      container = false;
      break;
    case 'components/@material/page':
      xs = 12;
      sm = 12;
      container = true;
      break;
    default:
      xs = 12;
      sm = 12;
      container = false;
  }

  return { xs, sm, container };
}

export default LazyComponentLoader;
